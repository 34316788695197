/* --------------------------------------------------
 Utility
-------------------------------------------------- */

// ----------------------------------------
// Show / Hidden
// ----------------------------------------

.is-onlyPC {
  @include SP() {
    display: none;
  }
}

.is-onlySP {
  @include PC() {
    display: none;
  }
}

.is-hidden {
  display: none !important;
}



// ----------------------------------------
// hover
// ----------------------------------------

.u-hover {
  cursor: pointer;
  transition: .2s;

  &:hover {
    opacity: .7;
  }
}



// ----------------------------------------
// font
// ----------------------------------------

.u-fontAjust {
  font-feature-settings: "palt" 1;
}

.u-font-HiraginoKakuGothicProN {
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', sans-serif;
}