/* --------------------------------------------------
base
-------------------------------------------------- */

body {
  background: #fff;
  color: #000;
  font-size: 15px;
  line-height: (34/15);
  counter-reset: number 0; // カウンタを0にリセット（!削除しないこと）

  &.is-fixed {
    overflow: hidden !important;
  }

  &.p-lower {
    color: $gray;

    @include PC() {
      min-width: $min-width--lower;
    }
  }
}

.is-gnav-fixed {
  @include SP() {
    overflow: hidden !important;
  }
}

section {
  position: relative;
}

main {
  display: block;
}

img {
  vertical-align: top;
}

// 解析系タグの画像を削除
body > img {
  width: 0;
  height: 0;
  line-height: 0;
  font-size: 0;
}

.l-wrap img {
  @include SP() {
    width: 100%;
    height: auto;
  }
}

a {
  &, &:active, &:visited, &:hover {
    color: #000;
  }
}

sup {
  vertical-align: super;
  font-size: smaller;
  font-family: 'Roboto', 'Verdana', 'Droid Sans', sans-serif;
}

small {
  font-family: 'Roboto', 'Verdana', 'Droid Sans', sans-serif;
}


// *::-webkit-scrollbar {
//     width: 3px;
//     height: 3px;
// }

// *::-webkit-scrollbar-track {
//     background: $scroll-rail-color;
// }

// *::-webkit-scrollbar-thumb {
//     background: $scroll-thumb-color;
// }