/* --------------------------------------------------
l-flow
-------------------------------------------------- */
.l-flow {
  border-top: 2px solid #f4f4f4;
  padding: 100px 0;

  @include SP() {
    padding: 60px 0;
  }
}

.l-flow__ttl {
  margin-bottom: 67px;

  @include SP() {
    margin-bottom: 48px;
  }
}

.l-flow__order {
  margin: 0 auto;

  @include PC() {
    width: 960px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @include SP() {
    width: (460/750)*100vw;
  }
}

.l-flow__order__item {
  position: relative;

  border: 2px solid;
  border-radius: 8px;
  text-align: center;
  padding: 55px 0 0;

  #p-smacolle & { border-color: $smacolle }
  #p-twiloto  & { border-color: $twiloto }

  @include PC() {
    width: 260px;
    height: 280px;
  }

  @include SP() {
    border-radius: 6px;
    padding: 25px 0;

    &:nth-of-type(n+2) {
      margin-top: 70px;
    }
  }
}

// 矢印
.l-flow__order__item:nth-of-type(n+2)::before {
  position: absolute;
  content: '';
  display: block;
  width: 34px;
  height: 34px;
  border: 10px solid;
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);

  #p-smacolle & { border-color: $smacolle }
  #p-twiloto  & { border-color: $twiloto }

  @include PC() {
    top: 0;
    bottom: 0;
    left: -72px;
    margin: auto 0;
  }

  @include SP() {
    width: 27px;
    height: 27px;
    border-width: 8px;
    border-bottom: none;
    border-left: none;
    top: -55px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: rotate(135deg);
  }
}

.l-flow__order__num {
  font-size: 70px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -0.04em;
  margin-bottom: 27px;

  #p-smacolle & { color: $smacolle }
  #p-twiloto  & { color: $twiloto }

  @include SP() {
    font-size: 50px;
    margin-bottom: 13px;
  }
}

.l-flow__order__txt {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;

  @include SP() {
    font-size: 14px;
    line-height: 21px;
  }
}

.l-flow__txt {
  #p-smacolle & {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin: 84px 0 45px;

    @include SP() {
      font-size: 14px;
      line-height: 25px;
      margin: 35px 0 25px;
    }
  }

  #p-twiloto & {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    margin: 54px 0 40px;

    @include SP() {
      font-size: 14px;
      line-height: 25px;
      margin: 45px 0 25px;
    }
  }

  span {
    font-size: 16px;

    @include SP() {
      font-size: 12px;
      font-weight: 400;
    }
  }
}


.l-flow__btn {
}