/* --------------------------------------------------
c-btn
-------------------------------------------------- */

// ------------------------------
// 共通設定
// ------------------------------

.c-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-btn a {
  @extend .u-font-HiraginoKakuGothicProN;
  width: 350px;
  height: 70px;
  border-radius: 35px / 50%;

  display: inline-block;
  text-decoration: none;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 600;
  font-size: 18px;
  line-height: 1;

  @include SP() {
    max-width: 230px;
    height: 55px;
    border-radius: 27.5px / 50%;
    font-size: 17px;
  }

  transition-duration: .4s;

  &:hover {
    transition-duration: .1s;
  }
}



// ------------------------------
// 青いボタン
// ------------------------------

.c-btn--blue {
}

.c-btn--blue a {
  background: #049ad7;
  color: #fff;

  transition-property: background, color, border;

  &:hover {
    background: #fff;
    color: #049ad7;
    border: 2px solid #049ad7;
  }
}



// ------------------------------
// 白いボタン
// ------------------------------

.c-btn--white {
}

.c-btn--white a {
  background: #fff;
  color: #333;

  transition-property: transform;

  &:hover {
    transform: scale(1.02);
  }
}



// ------------------------------
// オレンジのボタン
// ------------------------------

.c-btn--orange {
}

.c-btn--orange a {
  background: $orange;
  color: #fff;
  border: 2px solid #fff;

  transition-property: transform;

  &:hover {
    transform: scale(1.02);
  }
}



// ------------------------------
// メインビジュアル内
// ------------------------------
.l-mv {
  @include PC() {
    .c-btn {
    }

    .c-btn a {
      width: 210px;
      height: 54px;
      border-radius: 27px / 50%;
      font-size: 16px;
    }
  }
}