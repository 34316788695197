/* --------------------------------------------------
c-price
-------------------------------------------------- */
.c-price {
  @extend .u-font-HiraginoKakuGothicProN;
  text-align: center;
  font-weight: 600;
  line-height: 30px;
  text-indent: 1.5em;
  white-space: nowrap;

  @include SP() {
    line-height: 15px;
  }

  > * {
    vertical-align: baseline;
  }

  .l-pricing__formula & {
    text-indent: 0;
  }
}

.c-price--num {
  color: #049ad7;
  font-size: 50px;
  letter-spacing: -0.12em;
  margin-right: 0.12em;

  @include SP() {
    font-size: 35px;
  }

  &.-orange {
    color: #ff8e23;
  }

  &.-noJustification {
    letter-spacing: 0;
  }

  .l-pricing__formula & {
    margin-right: 0;
  }
}

.c-price--yen {
  color: $gray;
  font-size: 28px;

  @include SP() {
    font-size: 20px;
  }
}

.c-price--tax {
  color: $gray;
  font-size: 14px;
  margin-left: -0.5em;

  @include SP() {
    font-size: 12px;
  }
}



// ----------------------------------------
// 月額費用 10万円
// ----------------------------------------

.l-pricing__hdg02 + .c-price {
  margin-top: 46px;

  @include SP() {
    margin-top: 32px;
  }
}



// ----------------------------------------
// 月額費用 20万円（10万円 × 2ヶ月）
// ----------------------------------------

.l-pricing__formula .c-price.-price20 {
  .c-price--yen {
    margin-left: -0.1em;
  }
}



// ----------------------------------------
// 合計 35万円
// ----------------------------------------

.l-pricing__formula .c-price.-price35 {
  text-indent: 1em;

  .c-price--num {
    letter-spacing: 0;
  }

  .c-price--yen {
    margin-left: -0.1em;
  }

  .c-price--tax {
    margin-left: -0.9em;
  }
}