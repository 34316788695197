/* --------------------------------------------------
lineup
-------------------------------------------------- */
.lineup {
  padding: 90px 0 50px;

  @include SP() {
    padding: (80/750)*100vw 0 (20/750)*100vw;
  }
}

.lineup__ttl {
  line-height: 0;
  position: relative;
  margin: 0 auto;
  width: 282px;
  padding-bottom: 40px;

  @include SP() {
    width: (376/750)*100vw;
    padding-bottom: (43/750)*100vw;
  }

  img {
    width: 100%;
    height: auto;
  }

  &::before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    content: '';
    display: block;
    background: #f20516;
    width: 60px;
    height: 2px;

    @include SP() {
      width: (80/750)*100vw;
      height: (4/750)*100vw;
    }
  }
}

.lineup__sec {
  padding: 90px 0 70px;

  @include SP() {
    padding: (120/750)*100vw 0 (100/750)*100vw;
  }

  &:first-of-type {
    padding-top: 80px;

    @include SP() {
      padding-top: (130/750)*100vw;
    }
  }

  &:nth-of-type(n+2) {
    border-top: 2px solid #f4f4f4;
  }
}

.lineup__inner {

  @include PC() {
    width: $min-width;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  @include SP() {
    padding: 0 (60/750)*100vw;
  }
}

.lineup__logo {
  line-height: 0;
  text-align: center;

  @include PC() {
    width: 50%;
    padding-top: 80px;
  }

  @include SP() {
    margin: 0 auto (100/750)*100vw;
  }

  a {
    display: inline-block;
  }

  img {
    @include PC() {
      width: 100%;
      height: auto;
    }
  }
}

// ロゴのサイズ調整
.lineup__logo {

  @include SP() {
    .-kakusan  & a { width: (530/750)*100vw }
    .-smacolle & a { width: (433/750)*100vw }
    .-twiloto  & a { width: (410/750)*100vw }
  }

  @include PC() {
    .-kakusan  & a { width: 396px }
    .-smacolle & a { width: 340px }
    .-twiloto  & a { width: 324px }
  }
}


.lineup__box {
  @include PC() {
    width: 50%;
  }
}

.lineup__hdg {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 38px;

  @include SP() {
    height: (60/750)*100vw;
    border-radius: (4/750)*100vw;
    font-size: (30/750)*100vw;
    margin-bottom: (50/750)*100vw;
  }

  .-kakusan  & { background: $kakusan }
  .-smacolle & { background: $smacolle }
  .-twiloto & { background: $twiloto }
}

.lineup__lead {
  font-size: 21px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 20px;

  @include SP() {
    text-align: center;
    font-size: (36/750)*100vw;
    line-height: (54/36);
  }
}

.lineup__desc {
  font-size: 14px;
  line-height: (26/14);

  @include SP() {
    font-size: (28/750)*100vw;
    line-height: (52/28);
    text-align: justify;
  }
}

.lineup__btn {
  line-height: 1;

  @include PC() {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 46px;
  }

  @include SP() {
    margin-top: (72/750)*100vw;
  }
}

.lineup__btn__item {

  &:nth-of-type(n+2) {
    margin-left: 20px;

    @include SP() {
      margin: (20/750)*100vw 0 0;
    }
  }

  a {
    text-decoration: none;
    width: 230px;
    height: 50px;
    display: flex;
    border-radius: 25px;
    justify-content: center;
    align-items: center;
    font-weight: 600;

    transition-duration: .1s;
    transition-property: background, color, transform;

    @include SP() {
      width: 100%;
      height: (90/750)*100vw;
      border-radius: (45/750)*100vw;
    }

    &:hover {
      transform: scale(1.03);
    }

    &:active {
      transform: scale(0.99);
    }
  }
}


// 詳しくはこちら
.lineup__btn__item.-arw a {
  font-size: 14px;
  color: $lightblue;
  border: 1px solid $lightblue;

  @include SP() {
    font-size: (28/750)*100vw;
  }

  span {
    &::after {
      content: '＞';
      margin-left: 0.7em;
    }
  }

  &:hover {
    background: $lightblue;
    color: #fff;
  }
}


// 資料請求はこちら
.lineup__btn__item.-doc a {
  font-size: 16px;
  background: $lightblue;
  color: #fff;

  @include SP() {
    font-size: (30/750)*100vw;
  }

  &:hover {
    .-kakusan  & { background: $kakusan }
    .-smacolle & { background: $smacolle }
    .-twiloto & { background: $twiloto }
  }
}