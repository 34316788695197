/* --------------------------------------------------
l-pricing
-------------------------------------------------- */

.l-pricing {
  background: #f9f9f9;
  padding: 100px 0 90px;

  @include SP() {
    padding: 60px 0;
  }
}


/* ----------------------------------------
l-pricing: smacolle
---------------------------------------- */

#p-smacolle {

  .l-pricing__ttl {
    margin-bottom: 80px;

    @include SP() {
      margin-bottom: 40px;
    }
  }

  .l-pricing__sec {
    width: 960px;
    margin: 0 auto;
    padding: 50px;
    background: #fff;
    border-radius: 4px;

    @include SP() {
      width: (630/750)*100vw;
      border-radius: 6px;
      padding: 40px (85/750)*100vw 30px;

      &.-sec01 {
        padding-bottom: 48px;
      }
    }
  }
  .l-pricing__sec:nth-of-type(n+2) {
    margin-top: 50px;

    @include SP() {
      margin-top: 30px;
    }
  }

  .l-pricing__hdg01 {
    @extend .u-font-HiraginoKakuGothicProN;
    background: $smacolle;
    color: #fff;
    font-weight: 600;
    text-align: center;
    font-size: 23px;
    line-height: 1;
    padding: 13px 0;
    border-radius: 4px 4px 0 0 / 4px 4px 0 0;

    @include SP() {
      font-size: 14px;
      padding: 8px 0;
      border-radius: 2px 2px 0 0 / 2px 2px 0 0;
    }
  }

  .l-pricing__secInner {
    padding: 35px 0 58px;

    @include PC() {
      background: #f4f4f4;
      border-radius: 0 0 4px 4px / 0 0 4px 4px;
      overflow: hidden;
    }

    @include SP() {
      padding: 25px 0 0;
      border-radius: 0 0 2px 2px / 0 0 2px 2px;
    }
  }

  .l-pricing__box {
    @include PC() {
      width: 720px;
      margin: 0 auto;
    }

    &:nth-of-type(n+2) {
      margin-top: 40px;
    }
  }

  .l-pricing__hdg02 {
    @extend .u-font-HiraginoKakuGothicProN;
    background: #999;
    text-align: center;
    color: #fff;
    font-weight: 600;
    border-radius: 4px;
    font-size: 20px;
    line-height: 1;
    padding: 11px 0 12px;
    margin-bottom: 40px;

    @include SP() {
      border-radius: 2px;
      font-size: 14px;
      line-height: 15px;
      padding: 9px 0 6px;
      margin-bottom: 25px;

      .l-pricing__box:nth-of-type(n+2) & {
        margin-bottom: 30px;
      }
    }
  }

  .l-pricing__list {
    @include PC() {
      display: flex;
      justify-content: space-between;
    }
  }

  .l-pricing__list__box {
    @include PC() {
      width: 340px;
    }

    @include SP() {
      &:nth-of-type(n+2) {
        margin-top: 20px;
      }
    }
  }

  .l-pricing__list__case {
    background: $smacolle;
    color: #fff;
    font-weight: 600;
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    border-radius: 4px 4px 0 0 / 4px 4px 0 0;
    padding: 20px 0;

    @include SP() {
      font-size: 18px;
      line-height: 25px;
      border-radius: 2px 2px 0 0 / 2px 2px 0 0;
      padding: 13px 0 17px;
    }
  }
  .l-pricing__list__case span {
    font-size: 18px;

    @include SP() {
      font-size: 15px;
    }
  }

  .l-pricing__list__price {
    text-align: center;
    border: 2px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px / 0 0 4px 4px;
    padding: 46px 0 28px;

    @include SP() {
      border-radius: 0 0 2px 2px / 0 0 2px 2px;
      padding: 27px 0 17px;
    }
  }

  .l-pricing__txt {
    font-weight: 600;
    text-align: center;
    // white-space: nowrap;
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 42px;

    @include SP() {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 14px;
    }
  }
  .l-pricing__txt span {
    font-size: 16px;

    @include SP() {
      font-size: 13px;
    }
  }

  .l-pricing__formula {
    text-align: center;
    margin: 0 auto;

    @include PC() {
      width: 724px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .l-pricing__formula__box {
    background: #fff;
    width: 200px;
    height: 200px;
    border-radius: 10px;
    padding: 45px 0;
    position: relative;

    @include PC() {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    @include SP() {
      border: 2px solid #ccc;
      border-radius: 4px;
      width: 100%;
      height: auto;
      padding: 20px 0;
    }
  }

  .l-pricing__formula__operator {
    position: relative;
    display: block;
    width: 34px;
    height: 34px;
    font-size: 0;

    @include SP() {
      width: 17px;
      height: 17px;
      margin: 10px auto;
    }
  }
  .l-pricing__formula__operator::before,
  .l-pricing__formula__operator::after {
    position: absolute;
    content: '';
    display: block;
    background: #d2d2d2;
    width: 34px;
    height: 8px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    @include SP() {
      width: 17px;
      height: 4px;
    }
  }

  // プラス「＋」
  .l-pricing__formula__operator.-plus::after {
    transform: rotate(90deg);
  }

  // イコール「＝」
  .l-pricing__formula__operator.-equal {
    height: 26px;

    @include SP() {
      height: 13px;
      transform: rotate(90deg);
    }
  }
  .l-pricing__formula__operator.-equal::before {
    bottom: auto;
  }
  .l-pricing__formula__operator.-equal::after {
    top: auto;
  }

  .l-pricing__formula__ttl {
    font-weight: 600;
    font-size: 20px;
    line-height: 1;

    @include SP() {
      font-size: 15px;
      margin-bottom: 24px;

      // 合計
      .-sum & {
        margin-bottom: 20px;
      }
    }
  }
  .l-pricing__formula__ttl span {
    font-size: 12px;
    margin-top: 8px;

    @include SP() {
      font-size: 10px;
    }
  }


  .l-pricing__notes {
    font-size: 14px;
    line-height: 2;
    text-align: center;
    font-weight: 600;
    font-weight: 500;
    margin: 55px 0 42px;

    @include SP() {
      font-size: 11px;
      line-height: 20px;
      margin: 25px 0 35px;
    }
  }


  .l-pricing__btn {
  }

}



/* ----------------------------------------
l-pricing: twiloto
---------------------------------------- */

#p-twiloto {

  .l-pricing__ttl {
    margin-bottom: 60px;

    @include SP() {
      margin-bottom: 40px;
    }
  }

  .l-pricing__sec {
    width: 960px;
    margin: 0 auto;
    padding: 50px;
    background: #fff;
    border-radius: 4px;

    @include SP() {
      width: (630/750)*100vw;
      border-radius: 6px;
      padding: 40px (85/750)*100vw 30px;

      &.-sec01 {
        padding-bottom: 48px;
      }
    }
  }

  .l-pricing__hdg01 {
    @extend .u-font-HiraginoKakuGothicProN;
    background: $twiloto;
    color: #fff;
    font-weight: 600;
    text-align: center;
    font-size: 23px;
    line-height: 1;
    padding: 13px 0;
    border-radius: 4px;

    @include SP() {
      font-size: 14px;
      padding: 8px 0;
      border-radius: 2px 2px 0 0 / 2px 2px 0 0;
    }
  }

  .l-pricing__secInner {
    padding: 75px 0 0;

    @include SP() {
      padding: 47px 0 0;
    }
  }

  .l-pricing__box {
    @include PC() {
      width: 720px;
      margin: 0 auto;
    }

    &:nth-of-type(n+2) {
      margin-top: 40px;
    }
  }

  .l-pricing__hdg02 {
    @extend .u-font-HiraginoKakuGothicProN;
    background: #999;
    text-align: center;
    color: #fff;
    font-weight: 600;
    border-radius: 4px;
    font-size: 20px;
    line-height: 1;
    padding: 11px 0 12px;
    margin-bottom: 40px;

    @include SP() {
      border-radius: 2px;
      font-size: 14px;
      line-height: 15px;
      padding: 9px 0 6px;
      margin-bottom: 25px;

      .l-pricing__box:nth-of-type(n+2) & {
        margin-bottom: 30px;
      }
    }
  }

  .l-pricing__list {
    @include PC() {
      display: flex;
      justify-content: space-between;
    }
  }

  .l-pricing__list__box {
    @include PC() {
      width: 340px;
    }

    @include SP() {
      &:nth-of-type(n+2) {
        margin-top: 20px;
      }
    }
  }

  .l-pricing__list__case {
    background: $smacolle;
    color: #fff;
    font-weight: 600;
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    border-radius: 4px 4px 0 0 / 4px 4px 0 0;
    padding: 20px 0;

    @include SP() {
      font-size: 18px;
      line-height: 25px;
      border-radius: 2px 2px 0 0 / 2px 2px 0 0;
      padding: 13px 0 17px;
    }
  }
  .l-pricing__list__case span {
    font-size: 18px;

    @include SP() {
      font-size: 15px;
    }
  }

  .l-pricing__list__price {
    text-align: center;
    border: 2px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px / 0 0 4px 4px;
    padding: 46px 0 28px;

    @include SP() {
      border-radius: 0 0 2px 2px / 0 0 2px 2px;
      padding: 27px 0 17px;
    }
  }

  .l-pricing__txt {
    font-weight: 600;
    text-align: center;
    // white-space: nowrap;
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 42px;

    @include SP() {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 14px;
    }
  }
  .l-pricing__txt span {
    font-size: 16px;

    @include SP() {
      font-size: 13px;
    }
  }

  .l-pricing__formula {
    text-align: center;
    margin: 0 auto;

    @include PC() {
      width: 724px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .l-pricing__formula__box {
    background: #fff;
    width: 200px;
    height: 200px;
    border-radius: 10px;
    padding: 45px 0;
    position: relative;

    @include PC() {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    @include SP() {
      border: 2px solid #ccc;
      border-radius: 4px;
      width: 100%;
      height: auto;
      padding: 20px 0;
    }
  }

  .l-pricing__formula__operator {
    position: relative;
    display: block;
    width: 34px;
    height: 34px;
    font-size: 0;

    @include SP() {
      width: 17px;
      height: 17px;
      margin: 10px auto;
    }
  }
  .l-pricing__formula__operator::before,
  .l-pricing__formula__operator::after {
    position: absolute;
    content: '';
    display: block;
    background: #d2d2d2;
    width: 34px;
    height: 8px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    @include SP() {
      width: 17px;
      height: 4px;
    }
  }

  // プラス「＋」
  .l-pricing__formula__operator.-plus::after {
    transform: rotate(90deg);
  }

  // イコール「＝」
  .l-pricing__formula__operator.-equal {
    height: 26px;

    @include SP() {
      height: 13px;
      transform: rotate(90deg);
    }
  }
  .l-pricing__formula__operator.-equal::before {
    bottom: auto;
  }
  .l-pricing__formula__operator.-equal::after {
    top: auto;
  }

  .l-pricing__formula__ttl {
    font-weight: 600;
    font-size: 20px;
    line-height: 1;

    @include SP() {
      font-size: 15px;
      margin-bottom: 24px;

      // 合計
      .-sum & {
        margin-bottom: 20px;
      }
    }
  }
  .l-pricing__formula__ttl span {
    font-size: 12px;
    margin-top: 8px;

    @include SP() {
      font-size: 10px;
    }
  }


  .l-pricing__notes {
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    font-weight: 600;
    font-weight: 400;
    margin: 45px 0 0;

    @include SP() {
      text-align: left;
      font-size: 12px;
      line-height: 20px;
      margin: 33px 0 0;
    }
  }


  .l-pricing__btn {
    margin-top: 60px;
  }

}