/* --------------------------------------------------
gnav
-------------------------------------------------- */
.gnav {
  width: 100%;

  @include SP() {
    background: #fff;
    position: absolute;
    top: $header-h--sp;
    height: calc( 100vh - 56px );
    overflow-y: scroll;
    padding-bottom: (200/750)*100vw; // iPhoneバグ防止

    left: -100vw;
    opacity: 0;
    transition:
      left .3s,
      opacity .5s;

    .is-active & {
      left: 0;
      opacity: 1;
    }
  }
}

.gnav__list {
  @include PC() {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.gnav__item {
  line-height: 1;

  @include PC() {
    margin: 0 22px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  @include SP() {
    text-align: center;
    border-bottom: 2px solid;

    #p-smacolle & { border-color: $smacolle }
    #p-twiloto  & { border-color: $twiloto }
  }
}

.gnav__item a {
  text-decoration: none;
  font-size: 14px;
  line-height: 1;

  @include PC() {
    transition: opacity .3s;

    &:hover {
      opacity: .5;
    }
  }

  @include SP() {
    display: block;
    font-size: 23px;
    color: $gray;
    padding: 25px 0;

    &:active {
      color: #fff;

      #p-smacolle & { background: $smacolle }
      #p-twiloto  & { background: $twiloto }
    }
  }
}