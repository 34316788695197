/* --------------------------------------------------
font
-------------------------------------------------- */

@import url(http://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/css/yakuhanjp.min.css);

body {
  font-family:
    YakuHanJP,
    'Hiragino Sans', 'ヒラギノ角ゴシック',
    'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN',
    'メイリオ', 'Meiryo',
    'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
}