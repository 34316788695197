/* --------------------------------------------------
header
-------------------------------------------------- */
.header {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $header-h--pc;
  background: #fff;

  @include PC() {
    box-shadow: 0px 0px 4.75px 0.25px rgba(153, 153, 153, 0.4);
  }

  @include SP() {
    height: $header-h--sp;
    border-bottom: 1px solid #e6e6e6;
  }

  .p-top & {
    position: absolute;
  }
}


.header__inner {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: $min-width--lower;
  margin: 0 auto;

  @include PC() {
    justify-content: space-between;
    padding: 0 120px;
  }

  @include w1300() {
    padding: 0 (120/1300)*100%;
  }

  @include SP() {
    justify-content: center;
  }
}


.header__ttl {
  a {
    display: block;
    font-size: 0;
  }
}

.header__toggle {
  position: absolute;
  top: 0;
  right: 0;
  width: 61px;
  height: 100%;

  @include PC() {
    display: none;
  }
}

.header__toggle__item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 25px;
  height: 3px;
  border-radius: 1.5px;

  transition: .1s;

  &:nth-of-type(1) {
    transform: translateY(-8px);
  }
  &:nth-of-type(3) {
    transform: translateY(8px);
  }

  .is-active & {
    &:nth-of-type(1) {
      transform: rotate(-45deg);
    }
    &:nth-of-type(3) {
      transform: rotate(45deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
  }

  #p-smacolle & { background: $smacolle }
  #p-twiloto  & { background: $twiloto }
}



//--------------------------------------------------
// top
//--------------------------------------------------
#p-top {

  .header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .header__ttl {
    width: 224px;
    margin-top: 23px;

    @include SP() {
      width: 124px;
      margin-top: 15px;
    }
  }

  .header__ttl {
    img {
      @include PC() {
        width: 100%;
        height: auto;
      }
    }
  }
}



//--------------------------------------------------
// lower
//--------------------------------------------------
.p-lower {
  .header__ttl {
    img {
      width: auto;
      height: 40px;

      @include SP() {
        width: 100px;
        height: auto;
      }
    }
  }
}