/* --------------------------------------------------
layout
-------------------------------------------------- */
.l-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: $header-h--pc;

  @include PC() {
    min-width: $min-width;
  }

  @include SP() {
    padding-top: $header-h--sp;
  }

  // 下層ページ
  .p-lower & {
    padding-top: $header-h--pc;
    line-height: (34/15);

    @include SP() {
      padding-top: $header-h--sp;
    }
  }
}