@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url(http://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/css/yakuhanjp.min.css);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: bottom;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

img {
  vertical-align: bottom;
  border: none;
}

* {
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: border-box;
}

/*--------------------------------------------------
/* 印刷処理
--------------------------------------------------*/
* {
  -webkit-print-color-adjust: exact;
}

/* --------------------------------------------------
font
-------------------------------------------------- */
body {
  font-family: YakuHanJP, 'Hiragino Sans', 'ヒラギノ角ゴシック', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
}

/* --------------------------------------------------
base
-------------------------------------------------- */
body {
  background: #fff;
  color: #000;
  font-size: 15px;
  line-height: 2.26667;
  counter-reset: number 0;
}

body.is-fixed {
  overflow: hidden !important;
}

body.p-lower {
  color: #333333;
}

@media screen and (min-width: 769px) {
  body.p-lower {
    min-width: 1200px;
  }
}

@media screen and (max-width: 768px) {
  .is-gnav-fixed {
    overflow: hidden !important;
  }
}

section {
  position: relative;
}

main {
  display: block;
}

img {
  vertical-align: top;
}

body > img {
  width: 0;
  height: 0;
  line-height: 0;
  font-size: 0;
}

@media screen and (max-width: 768px) {
  .l-wrap img {
    width: 100%;
    height: auto;
  }
}

a, a:active, a:visited, a:hover {
  color: #000;
}

sup {
  vertical-align: super;
  font-size: smaller;
  font-family: 'Roboto', 'Verdana', 'Droid Sans', sans-serif;
}

small {
  font-family: 'Roboto', 'Verdana', 'Droid Sans', sans-serif;
}

/* --------------------------------------------------
c-btn
-------------------------------------------------- */
.c-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-btn a {
  width: 350px;
  height: 70px;
  border-radius: 35px / 50%;
  display: inline-block;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
  transition-duration: .4s;
}

@media screen and (max-width: 768px) {
  .c-btn a {
    max-width: 230px;
    height: 55px;
    border-radius: 27.5px / 50%;
    font-size: 17px;
  }
}

.c-btn a:hover {
  transition-duration: .1s;
}

.c-btn--blue a {
  background: #049ad7;
  color: #fff;
  transition-property: background, color, border;
}

.c-btn--blue a:hover {
  background: #fff;
  color: #049ad7;
  border: 2px solid #049ad7;
}

.c-btn--white a {
  background: #fff;
  color: #333;
  transition-property: transform;
}

.c-btn--white a:hover {
  transform: scale(1.02);
}

.c-btn--orange a {
  background: #ff8e23;
  color: #fff;
  border: 2px solid #fff;
  transition-property: transform;
}

.c-btn--orange a:hover {
  transform: scale(1.02);
}

@media screen and (min-width: 769px) {
  .l-mv .c-btn a {
    width: 210px;
    height: 54px;
    border-radius: 27px / 50%;
    font-size: 16px;
  }
}

/* --------------------------------------------------
c-price
-------------------------------------------------- */
.c-price {
  text-align: center;
  font-weight: 600;
  line-height: 30px;
  text-indent: 1.5em;
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .c-price {
    line-height: 15px;
  }
}

.c-price > * {
  vertical-align: baseline;
}

.l-pricing__formula .c-price {
  text-indent: 0;
}

.c-price--num {
  color: #049ad7;
  font-size: 50px;
  letter-spacing: -0.12em;
  margin-right: 0.12em;
}

@media screen and (max-width: 768px) {
  .c-price--num {
    font-size: 35px;
  }
}

.c-price--num.-orange {
  color: #ff8e23;
}

.c-price--num.-noJustification {
  letter-spacing: 0;
}

.l-pricing__formula .c-price--num {
  margin-right: 0;
}

.c-price--yen {
  color: #333333;
  font-size: 28px;
}

@media screen and (max-width: 768px) {
  .c-price--yen {
    font-size: 20px;
  }
}

.c-price--tax {
  color: #333333;
  font-size: 14px;
  margin-left: -0.5em;
}

@media screen and (max-width: 768px) {
  .c-price--tax {
    font-size: 12px;
  }
}

.l-pricing__hdg02 + .c-price {
  margin-top: 46px;
}

@media screen and (max-width: 768px) {
  .l-pricing__hdg02 + .c-price {
    margin-top: 32px;
  }
}

.l-pricing__formula .c-price.-price20 .c-price--yen {
  margin-left: -0.1em;
}

.l-pricing__formula .c-price.-price35 {
  text-indent: 1em;
}

.l-pricing__formula .c-price.-price35 .c-price--num {
  letter-spacing: 0;
}

.l-pricing__formula .c-price.-price35 .c-price--yen {
  margin-left: -0.1em;
}

.l-pricing__formula .c-price.-price35 .c-price--tax {
  margin-left: -0.9em;
}

/* --------------------------------------------------
c-ttl
-------------------------------------------------- */
.c-ttl {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .c-ttl {
    font-size: 21px;
  }
}

.c-ttl span {
  vertical-align: middle;
  font-size: 24px;
}

@media screen and (max-width: 768px) {
  .c-ttl span {
    font-size: 15px;
  }
}

/* --------------------------------------------------
common
-------------------------------------------------- */
/* --------------------------------------------------
footer
-------------------------------------------------- */
.footer {
  background: #313030;
  padding: 40px 0;
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 40px 10px;
  }
}

.footer__lowerNav {
  font-size: 0;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .footer__lowerNav {
    margin-bottom: 30px;
  }
}

.footer__lowerNav ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__lowerNav li {
  width: 180px;
  margin: 0 10px;
  transition: opacity .3s;
}

.footer__lowerNav li:hover {
  opacity: .5;
}

@media screen and (max-width: 768px) {
  .footer__lowerNav li {
    width: 95px;
    margin: 0 7px;
  }
}

.footer__lowerNav img {
  width: 100%;
  height: auto;
}

.footer__link {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .footer__link {
    font-size: 11px;
  }
}

.footer__link li {
  display: inline-block;
  margin: 0 23px;
}

@media screen and (max-width: 768px) {
  .footer__link li {
    margin: 0 12px;
  }
  .footer__link li:first-of-type {
    display: block;
    margin-bottom: 19px;
  }
}

.footer__link li a {
  color: #fff;
  text-decoration: none;
}

.footer__link li a:hover {
  text-decoration: underline;
}

/* --------------------------------------------------
gnav
-------------------------------------------------- */
.gnav {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .gnav {
    background: #fff;
    position: absolute;
    top: 56px;
    height: calc( 100vh - 56px);
    overflow-y: scroll;
    padding-bottom: 26.66667vw;
    left: -100vw;
    opacity: 0;
    transition: left .3s, opacity .5s;
  }
  .is-active .gnav {
    left: 0;
    opacity: 1;
  }
}

@media screen and (min-width: 769px) {
  .gnav__list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.gnav__item {
  line-height: 1;
}

@media screen and (min-width: 769px) {
  .gnav__item {
    margin: 0 22px;
  }
  .gnav__item:last-of-type {
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .gnav__item {
    text-align: center;
    border-bottom: 2px solid;
  }
  #p-smacolle .gnav__item {
    border-color: #5dc3db;
  }
  #p-twiloto .gnav__item {
    border-color: #f2c21d;
  }
}

.gnav__item a {
  text-decoration: none;
  font-size: 14px;
  line-height: 1;
}

@media screen and (min-width: 769px) {
  .gnav__item a {
    transition: opacity .3s;
  }
  .gnav__item a:hover {
    opacity: .5;
  }
}

@media screen and (max-width: 768px) {
  .gnav__item a {
    display: block;
    font-size: 23px;
    color: #333333;
    padding: 25px 0;
  }
  .gnav__item a:active {
    color: #fff;
  }
  #p-smacolle .gnav__item a:active {
    background: #5dc3db;
  }
  #p-twiloto .gnav__item a:active {
    background: #f2c21d;
  }
}

/* --------------------------------------------------
header
-------------------------------------------------- */
.header {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: #fff;
}

@media screen and (min-width: 769px) {
  .header {
    box-shadow: 0px 0px 4.75px 0.25px rgba(153, 153, 153, 0.4);
  }
}

@media screen and (max-width: 768px) {
  .header {
    height: 56px;
    border-bottom: 1px solid #e6e6e6;
  }
}

.p-top .header {
  position: absolute;
}

.header__inner {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

@media screen and (min-width: 769px) {
  .header__inner {
    justify-content: space-between;
    padding: 0 120px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1300px) {
  .header__inner {
    padding: 0 9.23077%;
  }
}

@media screen and (max-width: 768px) {
  .header__inner {
    justify-content: center;
  }
}

.header__ttl a {
  display: block;
  font-size: 0;
}

.header__toggle {
  position: absolute;
  top: 0;
  right: 0;
  width: 61px;
  height: 100%;
}

@media screen and (min-width: 769px) {
  .header__toggle {
    display: none;
  }
}

.header__toggle__item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 25px;
  height: 3px;
  border-radius: 1.5px;
  transition: .1s;
}

.header__toggle__item:nth-of-type(1) {
  transform: translateY(-8px);
}

.header__toggle__item:nth-of-type(3) {
  transform: translateY(8px);
}

.is-active .header__toggle__item:nth-of-type(1) {
  transform: rotate(-45deg);
}

.is-active .header__toggle__item:nth-of-type(3) {
  transform: rotate(45deg);
}

.is-active .header__toggle__item:nth-of-type(2) {
  opacity: 0;
}

#p-smacolle .header__toggle__item {
  background: #5dc3db;
}

#p-twiloto .header__toggle__item {
  background: #f2c21d;
}

#p-top .header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

#p-top .header__ttl {
  width: 224px;
  margin-top: 23px;
}

@media screen and (max-width: 768px) {
  #p-top .header__ttl {
    width: 124px;
    margin-top: 15px;
  }
}

@media screen and (min-width: 769px) {
  #p-top .header__ttl img {
    width: 100%;
    height: auto;
  }
}

.p-lower .header__ttl img {
  width: auto;
  height: 40px;
}

@media screen and (max-width: 768px) {
  .p-lower .header__ttl img {
    width: 100px;
    height: auto;
  }
}

/* --------------------------------------------------
l-carryout
-------------------------------------------------- */
.l-carryout {
  border-top: 2px solid #f4f4f4;
  padding: 100px 0;
}

@media screen and (max-width: 768px) {
  .l-carryout {
    padding: 60px 0;
  }
}

.l-carryout__ttl {
  margin-bottom: 97px;
}

@media screen and (max-width: 768px) {
  .l-carryout__ttl {
    margin-bottom: 56px;
  }
}

.l-carryout__grid {
  margin: 0 auto;
}

@media screen and (min-width: 769px) {
  .l-carryout__grid {
    width: 960px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .l-carryout__grid {
    width: 72vw;
  }
}

.l-carryout__item {
  position: relative;
}

@media screen and (min-width: 769px) {
  .l-carryout__item {
    width: 260px;
  }
}

@media screen and (min-width: 769px) {
  .l-carryout__item:nth-of-type(1) {
    order: 1;
  }
  .l-carryout__item:nth-of-type(2) {
    order: 2;
  }
  .l-carryout__item:nth-of-type(3) {
    order: 3;
  }
  .l-carryout__item:nth-of-type(4) {
    order: 6;
  }
  .l-carryout__item:nth-of-type(5) {
    order: 5;
  }
  .l-carryout__item:nth-of-type(6) {
    order: 4;
  }
  .l-carryout__item:nth-of-type(n+4) {
    margin-top: 137px;
  }
}

@media screen and (max-width: 768px) {
  .l-carryout__item:nth-of-type(n+2) {
    margin-top: 85px;
  }
}

.l-carryout__item::before {
  position: absolute;
  content: '';
  display: block;
  width: 40px;
  height: 73px;
  background: url("/assets/img/common/ico_arw_twiloto.png") no-repeat/contain;
}

@media screen and (max-width: 768px) {
  .l-carryout__item::before {
    width: 33px;
    height: 50px;
    background-image: url("/assets/img/common/ico_arw_twiloto@sp.png");
    bottom: -66px;
    left: 118px;
  }
}

@media screen and (min-width: 769px) {
  .l-carryout__item:nth-of-type(1)::before, .l-carryout__item:nth-of-type(2)::before {
    transform: rotate(-90deg);
    top: 63px;
    right: -66px;
  }
  .l-carryout__item:nth-of-type(3)::before {
    transform: rotate(0deg);
    bottom: -103px;
    right: 110px;
  }
  .l-carryout__item:nth-of-type(4)::before, .l-carryout__item:nth-of-type(5)::before {
    transform: rotate(90deg);
    top: 63px;
    left: -67px;
  }
}

.l-carryout__item:nth-of-type(6)::before {
  display: none;
}

.l-carryout__item::after {
  position: absolute;
  content: '';
  display: block;
  width: 75px;
  height: 16px;
  background: no-repeat center center / contain;
}

@media screen and (max-width: 768px) {
  .l-carryout__item::after {
    background-position: left center;
    bottom: -43px;
    left: 158px;
  }
}

.l-carryout__item:nth-of-type(1)::after {
  background-image: url("/assets/img/twiloto/txt_carryout01.png");
}

.l-carryout__item:nth-of-type(2)::after {
  background-image: url("/assets/img/twiloto/txt_carryout02.png");
}

.l-carryout__item:nth-of-type(3)::after {
  background-image: url("/assets/img/twiloto/txt_carryout03.png");
}

.l-carryout__item:nth-of-type(4)::after {
  background-image: url("/assets/img/twiloto/txt_carryout04.png");
}

.l-carryout__item:nth-of-type(5)::after {
  background-image: url("/assets/img/twiloto/txt_carryout05.png");
}

.l-carryout__item:nth-of-type(6)::after {
  display: none;
}

@media screen and (min-width: 769px) {
  .l-carryout__item:nth-of-type(1)::after, .l-carryout__item:nth-of-type(2)::after {
    top: 46px;
    right: -83px;
  }
  .l-carryout__item:nth-of-type(3)::after {
    bottom: -74px;
    right: 37px;
  }
  .l-carryout__item:nth-of-type(4)::after, .l-carryout__item:nth-of-type(5)::after {
    height: 20px;
    top: 46px;
    left: -83px;
  }
}

.l-carryout__visual {
  width: 190px;
  height: 180px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .l-carryout__visual {
    width: 40vw;
    height: auto;
  }
}

.l-carryout__visual img {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 769px) {
  .l-carryout__item:nth-of-type(6) .l-carryout__visual img {
    margin-top: -34px;
    margin-left: -4px;
    width: 206px;
  }
}

.l-carryout__txt {
  position: relative;
  font-weight: bold;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  margin-top: 35px;
  padding-left: 50px;
}

@media screen and (max-width: 768px) {
  .l-carryout__txt {
    line-height: 22px;
    margin-top: 20px;
  }
}

.l-carryout__txt::before {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: '';
  width: 36px;
  height: 36px;
  background: #f2c21d;
  color: #fff;
  font-size: 22px;
  line-height: 36px;
  text-align: center;
  font-weight: bold;
  border-radius: 2px;
  counter-increment: number 1;
  content: counter(number);
}

.l-carryout__btn {
  margin-top: 96px;
}

@media screen and (max-width: 768px) {
  .l-carryout__btn {
    margin-top: 46px;
  }
}

/* --------------------------------------------------
l-contact
-------------------------------------------------- */
.l-contact {
  color: #fff;
  text-align: center;
  padding: 90px 0 95px;
}

@media screen and (max-width: 768px) {
  .l-contact {
    padding: 60px 8vw;
  }
}

#p-smacolle .l-contact {
  background: #5dc3db;
}

#p-twiloto .l-contact {
  background: #f2c21d;
}

.l-contact__ttl {
  margin-bottom: 54px;
}

@media screen and (max-width: 768px) {
  .l-contact__ttl {
    margin-bottom: 43px;
  }
}

.l-contact__txt {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 44px;
}

@media screen and (max-width: 768px) {
  .l-contact__txt {
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 23px;
  }
}

/* --------------------------------------------------
l-feature
-------------------------------------------------- */
.l-feature {
  border-top: 2px solid #f4f4f4;
  padding: 100px 0;
}

@media screen and (max-width: 768px) {
  .l-feature {
    padding: 60px 0;
  }
}

.l-feature__ttl {
  margin-bottom: 83px;
}

@media screen and (max-width: 768px) {
  .l-feature__ttl {
    margin-bottom: 47px;
  }
}

.l-feature__grid {
  margin: 0 auto;
}

@media screen and (min-width: 769px) {
  .l-feature__grid {
    width: 960px;
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 768px) {
  .l-feature__grid {
    width: 72vw;
  }
}

.l-feature__sec {
  font-weight: 600;
}

@media screen and (min-width: 769px) {
  .l-feature__sec {
    width: 290px;
  }
}

@media screen and (max-width: 768px) {
  .l-feature__sec:nth-of-type(n+2) {
    margin-top: 50px;
  }
}

.l-feature__illust {
  width: 240px;
  height: 220px;
  margin: 0 auto 40px;
}

@media screen and (max-width: 768px) {
  .l-feature__illust {
    width: 49.33333vw;
    height: auto;
    margin-bottom: 15px;
  }
}

.l-feature__hdg {
  position: relative;
  white-space: nowrap;
  font-size: 21px;
  line-height: 41px;
  color: #000;
  padding-left: 60px;
}

@media screen and (max-width: 768px) {
  .l-feature__hdg {
    font-size: 18px;
    line-height: 30px;
    padding-left: 45px;
  }
}

.l-feature__hdg::before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  content: '';
  display: block;
  width: 50px;
  height: 41px;
  background: no-repeat / contain;
}

#p-smacolle .l-feature__hdg::before {
  background-image: url("/assets/img/common/ico_chack_smacolle.png");
}

#p-twiloto .l-feature__hdg::before {
  background-image: url("/assets/img/common/ico_chack_twiloto.png");
}

@media screen and (max-width: 768px) {
  .l-feature__hdg::before {
    width: 37px;
    height: 30px;
  }
}

.l-feature__desc {
  font-size: 14px;
  line-height: 28px;
  margin-top: 22px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .l-feature__desc {
    font-size: 14px;
    line-height: 26px;
    margin-top: 20px;
  }
}

.l-feature__txt {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 75px 0 45px;
}

@media screen and (max-width: 768px) {
  .l-feature__txt {
    font-size: 14px;
    line-height: 25px;
    margin: 40px 0 23px;
  }
}

/* --------------------------------------------------
l-flow
-------------------------------------------------- */
.l-flow {
  border-top: 2px solid #f4f4f4;
  padding: 100px 0;
}

@media screen and (max-width: 768px) {
  .l-flow {
    padding: 60px 0;
  }
}

.l-flow__ttl {
  margin-bottom: 67px;
}

@media screen and (max-width: 768px) {
  .l-flow__ttl {
    margin-bottom: 48px;
  }
}

.l-flow__order {
  margin: 0 auto;
}

@media screen and (min-width: 769px) {
  .l-flow__order {
    width: 960px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .l-flow__order {
    width: 61.33333vw;
  }
}

.l-flow__order__item {
  position: relative;
  border: 2px solid;
  border-radius: 8px;
  text-align: center;
  padding: 55px 0 0;
}

#p-smacolle .l-flow__order__item {
  border-color: #5dc3db;
}

#p-twiloto .l-flow__order__item {
  border-color: #f2c21d;
}

@media screen and (min-width: 769px) {
  .l-flow__order__item {
    width: 260px;
    height: 280px;
  }
}

@media screen and (max-width: 768px) {
  .l-flow__order__item {
    border-radius: 6px;
    padding: 25px 0;
  }
  .l-flow__order__item:nth-of-type(n+2) {
    margin-top: 70px;
  }
}

.l-flow__order__item:nth-of-type(n+2)::before {
  position: absolute;
  content: '';
  display: block;
  width: 34px;
  height: 34px;
  border: 10px solid;
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
}

#p-smacolle .l-flow__order__item:nth-of-type(n+2)::before {
  border-color: #5dc3db;
}

#p-twiloto .l-flow__order__item:nth-of-type(n+2)::before {
  border-color: #f2c21d;
}

@media screen and (min-width: 769px) {
  .l-flow__order__item:nth-of-type(n+2)::before {
    top: 0;
    bottom: 0;
    left: -72px;
    margin: auto 0;
  }
}

@media screen and (max-width: 768px) {
  .l-flow__order__item:nth-of-type(n+2)::before {
    width: 27px;
    height: 27px;
    border-width: 8px;
    border-bottom: none;
    border-left: none;
    top: -55px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: rotate(135deg);
  }
}

.l-flow__order__num {
  font-size: 70px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -0.04em;
  margin-bottom: 27px;
}

#p-smacolle .l-flow__order__num {
  color: #5dc3db;
}

#p-twiloto .l-flow__order__num {
  color: #f2c21d;
}

@media screen and (max-width: 768px) {
  .l-flow__order__num {
    font-size: 50px;
    margin-bottom: 13px;
  }
}

.l-flow__order__txt {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}

@media screen and (max-width: 768px) {
  .l-flow__order__txt {
    font-size: 14px;
    line-height: 21px;
  }
}

#p-smacolle .l-flow__txt {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin: 84px 0 45px;
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-flow__txt {
    font-size: 14px;
    line-height: 25px;
    margin: 35px 0 25px;
  }
}

#p-twiloto .l-flow__txt {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  margin: 54px 0 40px;
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-flow__txt {
    font-size: 14px;
    line-height: 25px;
    margin: 45px 0 25px;
  }
}

.l-flow__txt span {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .l-flow__txt span {
    font-size: 12px;
    font-weight: 400;
  }
}

/* --------------------------------------------------
l-mv
-------------------------------------------------- */
.l-mv {
  background: no-repeat center center / cover;
  width: 100%;
  overflow: hidden;
}

@media screen and (min-width: 769px) {
  .l-mv {
    min-width: 1200px;
    height: 420px;
  }
}

@media screen and (max-width: 768px) {
  .l-mv {
    max-height: 168vw;
  }
}

#p-smacolle .l-mv {
  background-color: #87d7e9;
  background-image: url("/assets/img/smacolle/mv_bg.jpg");
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-mv {
    background-image: url("/assets/img/smacolle/mv_bg@sp.jpg");
  }
}

#p-twiloto .l-mv {
  background-color: #f6d442;
  background-image: url("/assets/img/twiloto/mv_bg.jpg");
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-mv {
    background-image: url("/assets/img/twiloto/mv_bg@sp.jpg");
  }
}

.l-mv img {
  width: 100%;
  height: auto;
}

.l-mv__inner {
  position: relative;
  margin: 0 auto;
  height: 100%;
}

@media screen and (min-width: 769px) {
  #p-smacolle .l-mv__inner {
    max-width: 1300px;
  }
  #p-twiloto .l-mv__inner {
    max-width: 1200px;
  }
}

.l-mv__visual {
  z-index: 1;
  position: absolute;
}

@media screen and (max-width: 768px) {
  .l-mv__visual {
    left: 50%;
    transform: translateX(-50%);
  }
}

#p-smacolle .l-mv__visual {
  top: 50%;
  width: 51.53846%;
}

@media screen and (min-width: 769px) {
  #p-smacolle .l-mv__visual {
    left: 29%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-mv__visual {
    width: 94.66667vw;
    top: 50.4vw;
  }
}

@media screen and (min-width: 769px) {
  #p-twiloto .l-mv__visual {
    top: 74px;
    left: 100px;
    width: 497px;
  }
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-mv__visual {
    width: 89.33333vw;
    top: 56.66667vw;
  }
}

.l-mv__circle {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 15px;
}

@media screen and (max-width: 768px) {
  .l-mv__circle {
    top: 0;
    left: 0;
    width: 100vw;
  }
}

.l-mv__box {
  z-index: 2;
  position: relative;
  text-align: center;
  height: 100%;
}

@media screen and (min-width: 769px) {
  .l-mv__box {
    width: 630px;
    padding-bottom: 60px;
  }
  #p-smacolle .l-mv__box {
    margin: 0 0 0 auto;
  }
  #p-twiloto .l-mv__box {
    margin: 0 20px 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .l-mv__box {
    padding-bottom: 11.06667vw;
  }
}

.l-mv__copy {
  line-height: 0;
  margin: 0 auto;
}

#p-smacolle .l-mv__copy {
  padding-top: 79px;
  width: 344px;
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-mv__copy {
    width: 73.6vw;
    padding-top: 13.33333vw;
  }
}

#p-twiloto .l-mv__copy {
  padding-top: 100px;
  width: 444px;
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-mv__copy {
    width: 82.66667vw;
    padding-top: 16.26667vw;
  }
}

.l-mv__ttl {
  line-height: 0;
  margin: 0 auto;
}

#p-smacolle .l-mv__ttl {
  padding-top: 53px;
  width: 400px;
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-mv__ttl {
    width: 83.06667vw;
    padding-top: 9.33333vw;
  }
}

#p-twiloto .l-mv__ttl {
  padding-top: 30px;
  width: 435px;
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-mv__ttl {
    width: 90vw;
    padding-top: 6.66667vw;
  }
}

@media screen and (min-width: 769px) {
  .l-mv__list {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 430px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .l-mv__list {
    margin: 77.86667vw auto 0;
  }
  .l-mv__list > *:nth-child(n+2) {
    margin-top: 2.66667vw;
  }
}

@media screen and (min-width: 769px) {
  #p-smacolle .l-mv__list {
    bottom: 60px;
  }
}

@media screen and (min-width: 769px) {
  #p-twiloto .l-mv__list {
    bottom: 70px;
  }
}

/* --------------------------------------------------
l-pricing
-------------------------------------------------- */
.l-pricing {
  background: #f9f9f9;
  padding: 100px 0 90px;
}

@media screen and (max-width: 768px) {
  .l-pricing {
    padding: 60px 0;
  }
}

/* ----------------------------------------
l-pricing: smacolle
---------------------------------------- */
#p-smacolle .l-pricing__ttl {
  margin-bottom: 80px;
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-pricing__ttl {
    margin-bottom: 40px;
  }
}

#p-smacolle .l-pricing__sec {
  width: 960px;
  margin: 0 auto;
  padding: 50px;
  background: #fff;
  border-radius: 4px;
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-pricing__sec {
    width: 84vw;
    border-radius: 6px;
    padding: 40px 11.33333vw 30px;
  }
  #p-smacolle .l-pricing__sec.-sec01 {
    padding-bottom: 48px;
  }
}

#p-smacolle .l-pricing__sec:nth-of-type(n+2) {
  margin-top: 50px;
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-pricing__sec:nth-of-type(n+2) {
    margin-top: 30px;
  }
}

#p-smacolle .l-pricing__hdg01 {
  background: #5dc3db;
  color: #fff;
  font-weight: 600;
  text-align: center;
  font-size: 23px;
  line-height: 1;
  padding: 13px 0;
  border-radius: 4px 4px 0 0 / 4px 4px 0 0;
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-pricing__hdg01 {
    font-size: 14px;
    padding: 8px 0;
    border-radius: 2px 2px 0 0 / 2px 2px 0 0;
  }
}

#p-smacolle .l-pricing__secInner {
  padding: 35px 0 58px;
}

@media screen and (min-width: 769px) {
  #p-smacolle .l-pricing__secInner {
    background: #f4f4f4;
    border-radius: 0 0 4px 4px / 0 0 4px 4px;
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-pricing__secInner {
    padding: 25px 0 0;
    border-radius: 0 0 2px 2px / 0 0 2px 2px;
  }
}

@media screen and (min-width: 769px) {
  #p-smacolle .l-pricing__box {
    width: 720px;
    margin: 0 auto;
  }
}

#p-smacolle .l-pricing__box:nth-of-type(n+2) {
  margin-top: 40px;
}

#p-smacolle .l-pricing__hdg02 {
  background: #999;
  text-align: center;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
  font-size: 20px;
  line-height: 1;
  padding: 11px 0 12px;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-pricing__hdg02 {
    border-radius: 2px;
    font-size: 14px;
    line-height: 15px;
    padding: 9px 0 6px;
    margin-bottom: 25px;
  }
  .l-pricing__box:nth-of-type(n+2) #p-smacolle .l-pricing__hdg02 {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 769px) {
  #p-smacolle .l-pricing__list {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: 769px) {
  #p-smacolle .l-pricing__list__box {
    width: 340px;
  }
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-pricing__list__box:nth-of-type(n+2) {
    margin-top: 20px;
  }
}

#p-smacolle .l-pricing__list__case {
  background: #5dc3db;
  color: #fff;
  font-weight: 600;
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  border-radius: 4px 4px 0 0 / 4px 4px 0 0;
  padding: 20px 0;
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-pricing__list__case {
    font-size: 18px;
    line-height: 25px;
    border-radius: 2px 2px 0 0 / 2px 2px 0 0;
    padding: 13px 0 17px;
  }
}

#p-smacolle .l-pricing__list__case span {
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-pricing__list__case span {
    font-size: 15px;
  }
}

#p-smacolle .l-pricing__list__price {
  text-align: center;
  border: 2px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px / 0 0 4px 4px;
  padding: 46px 0 28px;
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-pricing__list__price {
    border-radius: 0 0 2px 2px / 0 0 2px 2px;
    padding: 27px 0 17px;
  }
}

#p-smacolle .l-pricing__txt {
  font-weight: 600;
  text-align: center;
  font-size: 22px;
  line-height: 34px;
  margin-bottom: 42px;
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-pricing__txt {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 14px;
  }
}

#p-smacolle .l-pricing__txt span {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-pricing__txt span {
    font-size: 13px;
  }
}

#p-smacolle .l-pricing__formula {
  text-align: center;
  margin: 0 auto;
}

@media screen and (min-width: 769px) {
  #p-smacolle .l-pricing__formula {
    width: 724px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

#p-smacolle .l-pricing__formula__box {
  background: #fff;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  padding: 45px 0;
  position: relative;
}

@media screen and (min-width: 769px) {
  #p-smacolle .l-pricing__formula__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-pricing__formula__box {
    border: 2px solid #ccc;
    border-radius: 4px;
    width: 100%;
    height: auto;
    padding: 20px 0;
  }
}

#p-smacolle .l-pricing__formula__operator {
  position: relative;
  display: block;
  width: 34px;
  height: 34px;
  font-size: 0;
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-pricing__formula__operator {
    width: 17px;
    height: 17px;
    margin: 10px auto;
  }
}

#p-smacolle .l-pricing__formula__operator::before,
#p-smacolle .l-pricing__formula__operator::after {
  position: absolute;
  content: '';
  display: block;
  background: #d2d2d2;
  width: 34px;
  height: 8px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-pricing__formula__operator::before,
  #p-smacolle .l-pricing__formula__operator::after {
    width: 17px;
    height: 4px;
  }
}

#p-smacolle .l-pricing__formula__operator.-plus::after {
  transform: rotate(90deg);
}

#p-smacolle .l-pricing__formula__operator.-equal {
  height: 26px;
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-pricing__formula__operator.-equal {
    height: 13px;
    transform: rotate(90deg);
  }
}

#p-smacolle .l-pricing__formula__operator.-equal::before {
  bottom: auto;
}

#p-smacolle .l-pricing__formula__operator.-equal::after {
  top: auto;
}

#p-smacolle .l-pricing__formula__ttl {
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-pricing__formula__ttl {
    font-size: 15px;
    margin-bottom: 24px;
  }
  .-sum #p-smacolle .l-pricing__formula__ttl {
    margin-bottom: 20px;
  }
}

#p-smacolle .l-pricing__formula__ttl span {
  font-size: 12px;
  margin-top: 8px;
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-pricing__formula__ttl span {
    font-size: 10px;
  }
}

#p-smacolle .l-pricing__notes {
  font-size: 14px;
  line-height: 2;
  text-align: center;
  font-weight: 600;
  font-weight: 500;
  margin: 55px 0 42px;
}

@media screen and (max-width: 768px) {
  #p-smacolle .l-pricing__notes {
    font-size: 11px;
    line-height: 20px;
    margin: 25px 0 35px;
  }
}

/* ----------------------------------------
l-pricing: twiloto
---------------------------------------- */
#p-twiloto .l-pricing__ttl {
  margin-bottom: 60px;
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-pricing__ttl {
    margin-bottom: 40px;
  }
}

#p-twiloto .l-pricing__sec {
  width: 960px;
  margin: 0 auto;
  padding: 50px;
  background: #fff;
  border-radius: 4px;
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-pricing__sec {
    width: 84vw;
    border-radius: 6px;
    padding: 40px 11.33333vw 30px;
  }
  #p-twiloto .l-pricing__sec.-sec01 {
    padding-bottom: 48px;
  }
}

#p-twiloto .l-pricing__hdg01 {
  background: #f2c21d;
  color: #fff;
  font-weight: 600;
  text-align: center;
  font-size: 23px;
  line-height: 1;
  padding: 13px 0;
  border-radius: 4px;
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-pricing__hdg01 {
    font-size: 14px;
    padding: 8px 0;
    border-radius: 2px 2px 0 0 / 2px 2px 0 0;
  }
}

#p-twiloto .l-pricing__secInner {
  padding: 75px 0 0;
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-pricing__secInner {
    padding: 47px 0 0;
  }
}

@media screen and (min-width: 769px) {
  #p-twiloto .l-pricing__box {
    width: 720px;
    margin: 0 auto;
  }
}

#p-twiloto .l-pricing__box:nth-of-type(n+2) {
  margin-top: 40px;
}

#p-twiloto .l-pricing__hdg02 {
  background: #999;
  text-align: center;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
  font-size: 20px;
  line-height: 1;
  padding: 11px 0 12px;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-pricing__hdg02 {
    border-radius: 2px;
    font-size: 14px;
    line-height: 15px;
    padding: 9px 0 6px;
    margin-bottom: 25px;
  }
  .l-pricing__box:nth-of-type(n+2) #p-twiloto .l-pricing__hdg02 {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 769px) {
  #p-twiloto .l-pricing__list {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: 769px) {
  #p-twiloto .l-pricing__list__box {
    width: 340px;
  }
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-pricing__list__box:nth-of-type(n+2) {
    margin-top: 20px;
  }
}

#p-twiloto .l-pricing__list__case {
  background: #5dc3db;
  color: #fff;
  font-weight: 600;
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  border-radius: 4px 4px 0 0 / 4px 4px 0 0;
  padding: 20px 0;
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-pricing__list__case {
    font-size: 18px;
    line-height: 25px;
    border-radius: 2px 2px 0 0 / 2px 2px 0 0;
    padding: 13px 0 17px;
  }
}

#p-twiloto .l-pricing__list__case span {
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-pricing__list__case span {
    font-size: 15px;
  }
}

#p-twiloto .l-pricing__list__price {
  text-align: center;
  border: 2px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px / 0 0 4px 4px;
  padding: 46px 0 28px;
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-pricing__list__price {
    border-radius: 0 0 2px 2px / 0 0 2px 2px;
    padding: 27px 0 17px;
  }
}

#p-twiloto .l-pricing__txt {
  font-weight: 600;
  text-align: center;
  font-size: 22px;
  line-height: 34px;
  margin-bottom: 42px;
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-pricing__txt {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 14px;
  }
}

#p-twiloto .l-pricing__txt span {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-pricing__txt span {
    font-size: 13px;
  }
}

#p-twiloto .l-pricing__formula {
  text-align: center;
  margin: 0 auto;
}

@media screen and (min-width: 769px) {
  #p-twiloto .l-pricing__formula {
    width: 724px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

#p-twiloto .l-pricing__formula__box {
  background: #fff;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  padding: 45px 0;
  position: relative;
}

@media screen and (min-width: 769px) {
  #p-twiloto .l-pricing__formula__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-pricing__formula__box {
    border: 2px solid #ccc;
    border-radius: 4px;
    width: 100%;
    height: auto;
    padding: 20px 0;
  }
}

#p-twiloto .l-pricing__formula__operator {
  position: relative;
  display: block;
  width: 34px;
  height: 34px;
  font-size: 0;
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-pricing__formula__operator {
    width: 17px;
    height: 17px;
    margin: 10px auto;
  }
}

#p-twiloto .l-pricing__formula__operator::before,
#p-twiloto .l-pricing__formula__operator::after {
  position: absolute;
  content: '';
  display: block;
  background: #d2d2d2;
  width: 34px;
  height: 8px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-pricing__formula__operator::before,
  #p-twiloto .l-pricing__formula__operator::after {
    width: 17px;
    height: 4px;
  }
}

#p-twiloto .l-pricing__formula__operator.-plus::after {
  transform: rotate(90deg);
}

#p-twiloto .l-pricing__formula__operator.-equal {
  height: 26px;
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-pricing__formula__operator.-equal {
    height: 13px;
    transform: rotate(90deg);
  }
}

#p-twiloto .l-pricing__formula__operator.-equal::before {
  bottom: auto;
}

#p-twiloto .l-pricing__formula__operator.-equal::after {
  top: auto;
}

#p-twiloto .l-pricing__formula__ttl {
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-pricing__formula__ttl {
    font-size: 15px;
    margin-bottom: 24px;
  }
  .-sum #p-twiloto .l-pricing__formula__ttl {
    margin-bottom: 20px;
  }
}

#p-twiloto .l-pricing__formula__ttl span {
  font-size: 12px;
  margin-top: 8px;
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-pricing__formula__ttl span {
    font-size: 10px;
  }
}

#p-twiloto .l-pricing__notes {
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  font-weight: 600;
  font-weight: 400;
  margin: 45px 0 0;
}

@media screen and (max-width: 768px) {
  #p-twiloto .l-pricing__notes {
    text-align: left;
    font-size: 12px;
    line-height: 20px;
    margin: 33px 0 0;
  }
}

#p-twiloto .l-pricing__btn {
  margin-top: 60px;
}

/* --------------------------------------------------
layout
-------------------------------------------------- */
.l-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 100px;
}

@media screen and (min-width: 769px) {
  .l-wrap {
    min-width: 1000px;
  }
}

@media screen and (max-width: 768px) {
  .l-wrap {
    padding-top: 56px;
  }
}

.p-lower .l-wrap {
  padding-top: 100px;
  line-height: 2.26667;
}

@media screen and (max-width: 768px) {
  .p-lower .l-wrap {
    padding-top: 56px;
  }
}

/* --------------------------------------------------
lineup
-------------------------------------------------- */
.lineup {
  padding: 90px 0 50px;
}

@media screen and (max-width: 768px) {
  .lineup {
    padding: 10.66667vw 0 2.66667vw;
  }
}

.lineup__ttl {
  line-height: 0;
  position: relative;
  margin: 0 auto;
  width: 282px;
  padding-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .lineup__ttl {
    width: 50.13333vw;
    padding-bottom: 5.73333vw;
  }
}

.lineup__ttl img {
  width: 100%;
  height: auto;
}

.lineup__ttl::before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  content: '';
  display: block;
  background: #f20516;
  width: 60px;
  height: 2px;
}

@media screen and (max-width: 768px) {
  .lineup__ttl::before {
    width: 10.66667vw;
    height: 0.53333vw;
  }
}

.lineup__sec {
  padding: 90px 0 70px;
}

@media screen and (max-width: 768px) {
  .lineup__sec {
    padding: 16vw 0 13.33333vw;
  }
}

.lineup__sec:first-of-type {
  padding-top: 80px;
}

@media screen and (max-width: 768px) {
  .lineup__sec:first-of-type {
    padding-top: 17.33333vw;
  }
}

.lineup__sec:nth-of-type(n+2) {
  border-top: 2px solid #f4f4f4;
}

@media screen and (min-width: 769px) {
  .lineup__inner {
    width: 1000px;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
}

@media screen and (max-width: 768px) {
  .lineup__inner {
    padding: 0 8vw;
  }
}

.lineup__logo {
  line-height: 0;
  text-align: center;
}

@media screen and (min-width: 769px) {
  .lineup__logo {
    width: 50%;
    padding-top: 80px;
  }
}

@media screen and (max-width: 768px) {
  .lineup__logo {
    margin: 0 auto 13.33333vw;
  }
}

.lineup__logo a {
  display: inline-block;
}

@media screen and (min-width: 769px) {
  .lineup__logo img {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .-kakusan .lineup__logo a {
    width: 70.66667vw;
  }
  .-smacolle .lineup__logo a {
    width: 57.73333vw;
  }
  .-twiloto .lineup__logo a {
    width: 54.66667vw;
  }
}

@media screen and (min-width: 769px) {
  .-kakusan .lineup__logo a {
    width: 396px;
  }
  .-smacolle .lineup__logo a {
    width: 340px;
  }
  .-twiloto .lineup__logo a {
    width: 324px;
  }
}

@media screen and (min-width: 769px) {
  .lineup__box {
    width: 50%;
  }
}

.lineup__hdg {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 38px;
}

@media screen and (max-width: 768px) {
  .lineup__hdg {
    height: 8vw;
    border-radius: 0.53333vw;
    font-size: 4vw;
    margin-bottom: 6.66667vw;
  }
}

.-kakusan .lineup__hdg {
  background: #f20516;
}

.-smacolle .lineup__hdg {
  background: #5dc3db;
}

.-twiloto .lineup__hdg {
  background: #f2c21d;
}

.lineup__lead {
  font-size: 21px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .lineup__lead {
    text-align: center;
    font-size: 4.8vw;
    line-height: 1.5;
  }
}

.lineup__desc {
  font-size: 14px;
  line-height: 1.85714;
}

@media screen and (max-width: 768px) {
  .lineup__desc {
    font-size: 3.73333vw;
    line-height: 1.85714;
    text-align: justify;
  }
}

.lineup__btn {
  line-height: 1;
}

@media screen and (min-width: 769px) {
  .lineup__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 46px;
  }
}

@media screen and (max-width: 768px) {
  .lineup__btn {
    margin-top: 9.6vw;
  }
}

.lineup__btn__item:nth-of-type(n+2) {
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  .lineup__btn__item:nth-of-type(n+2) {
    margin: 2.66667vw 0 0;
  }
}

.lineup__btn__item a {
  text-decoration: none;
  width: 230px;
  height: 50px;
  display: flex;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  transition-duration: .1s;
  transition-property: background, color, transform;
}

@media screen and (max-width: 768px) {
  .lineup__btn__item a {
    width: 100%;
    height: 12vw;
    border-radius: 6vw;
  }
}

.lineup__btn__item a:hover {
  transform: scale(1.03);
}

.lineup__btn__item a:active {
  transform: scale(0.99);
}

.lineup__btn__item.-arw a {
  font-size: 14px;
  color: #049ad7;
  border: 1px solid #049ad7;
}

@media screen and (max-width: 768px) {
  .lineup__btn__item.-arw a {
    font-size: 3.73333vw;
  }
}

.lineup__btn__item.-arw a span::after {
  content: '＞';
  margin-left: 0.7em;
}

.lineup__btn__item.-arw a:hover {
  background: #049ad7;
  color: #fff;
}

.lineup__btn__item.-doc a {
  font-size: 16px;
  background: #049ad7;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .lineup__btn__item.-doc a {
    font-size: 4vw;
  }
}

.-kakusan .lineup__btn__item.-doc a:hover {
  background: #f20516;
}

.-smacolle .lineup__btn__item.-doc a:hover {
  background: #5dc3db;
}

.-twiloto .lineup__btn__item.-doc a:hover {
  background: #f2c21d;
}

/* --------------------------------------------------
loader
-------------------------------------------------- */
.loader {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2c21d;
}

#p-smacolle .loader {
  background: #5dc3db;
}

#p-twiloto .loader {
  background: #f2c21d;
}

.self-building-square-spinner, .self-building-square-spinner * {
  box-sizing: border-box;
}

.self-building-square-spinner {
  height: 40px;
  width: 40px;
}

.self-building-square-spinner .square {
  height: 10px;
  width: 10px;
  top: -6.66667px;
  margin-right: 3.33333px;
  margin-top: 3.33333px;
  background: #fff;
  float: left;
  position: relative;
  opacity: 0;
  animation: self-building-square-spinner 4s infinite;
}

.self-building-square-spinner .square:nth-child(1) {
  animation-delay: 1200ms;
}

.self-building-square-spinner .square:nth-child(2) {
  animation-delay: 1400ms;
}

.self-building-square-spinner .square:nth-child(3) {
  animation-delay: 1600ms;
}

.self-building-square-spinner .square:nth-child(4) {
  animation-delay: 600ms;
}

.self-building-square-spinner .square:nth-child(5) {
  animation-delay: 800ms;
}

.self-building-square-spinner .square:nth-child(6) {
  animation-delay: 1000ms;
}

.self-building-square-spinner .square:nth-child(7) {
  animation-delay: 0ms;
}

.self-building-square-spinner .square:nth-child(8) {
  animation-delay: 200ms;
}

.self-building-square-spinner .square:nth-child(9) {
  animation-delay: 400ms;
}

.self-building-square-spinner .clear {
  clear: both;
}

@keyframes self-building-square-spinner {
  0% {
    opacity: 0;
    top: -6.66667px;
  }
  5% {
    opacity: 1;
    top: 0;
  }
  50.9% {
    opacity: 1;
    top: 0;
  }
  55.9% {
    opacity: 0;
    top: -6.66667px;
  }
}

/* --------------------------------------------------
top
-------------------------------------------------- */
.top__mv {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .top__mv {
    height: 50.66667vw;
  }
}

@media screen and (min-width: 769px) {
  .top__mv {
    padding-top: 19.79167%;
  }
}

@media screen and (min-width: 1921px) {
  .top__mv {
    padding-top: 380px;
  }
}

.top__mv::before {
  z-index: 3;
  position: absolute;
  top: 0;
  right: 0;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
}

.top__mv__bg {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: no-repeat #004986 url("/assets/img/top/mv_bg.jpg") center center/auto 100%;
}

@media screen and (max-width: 768px) {
  .top__mv__bg {
    background-image: url("/assets/img/top/mv_bg@sp.jpg");
    background-size: cover;
  }
}

.top__mv__lead {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25.625%;
  line-height: 0;
}

@media screen and (min-width: 769px) {
  .top__mv__lead {
    max-width: 492px;
  }
}

@media screen and (max-width: 768px) {
  .top__mv__lead {
    width: 69.33333vw;
  }
}

.top__mv__lead span {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
}

.top__mv__lead span:nth-of-type(n+2) {
  width: 77.03252%;
  margin: 6.50407% auto 0;
}

@media screen and (max-width: 768px) {
  .top__mv__lead span:nth-of-type(n+2) {
    width: 53.06667vw;
    margin-top: 5.6vw;
  }
}

.top__mv__lead span::before {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.top__mv__particle {
  z-index: 1;
  position: absolute;
  top: -10%;
  left: 0;
  width: 100%;
  height: 120%;
}

/* --------------------------------------------------
animation
-------------------------------------------------- */
.top__mv::before {
  width: 100%;
  transition: width 0.8s 0.3s cubic-bezier(1, 0, 0, 1);
}

.is-loaded .top__mv::before {
  width: 0;
}

.top__mv__bg {
  transform: translateX(-100px) scale(1.1);
  transition: transform 1s 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.is-loaded .top__mv__bg {
  transform: translateX(0);
}

.top__mv__lead span::before {
  animation: top__mv__lead .8s 1.2s both;
  animation-play-state: paused;
}

.is-loaded .top__mv__lead span:nth-of-type(n+2)::before {
  animation-delay: 1.3s;
}

.is-loaded .top__mv__lead span::before {
  animation-play-state: running;
}

@keyframes top__mv__lead {
  0% {
    transform: translate(-110%);
  }
  50%, 60% {
    transform: translate(0%);
    width: 100%;
  }
  100% {
    width: 0;
  }
}

.top__mv__lead span img {
  opacity: 0;
  transition-delay: 1.6s;
}

.is-loaded .top__mv__lead span img {
  opacity: 1;
}

.top__mv__lead span:nth-of-type(n+2) img {
  transition-delay: 1.7s;
}

.top__mv__particle {
  opacity: 0;
  transform: scale(1.1);
  transition: 1s 2s;
  transition-property: opacity, transform;
}

.is-loaded .top__mv__particle {
  opacity: .5;
  transform: scale(1);
}

/* --------------------------------------------------
 Utility
-------------------------------------------------- */
@media screen and (max-width: 768px) {
  .is-onlyPC {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .is-onlySP {
    display: none;
  }
}

.is-hidden {
  display: none !important;
}

.u-hover {
  cursor: pointer;
  transition: .2s;
}

.u-hover:hover {
  opacity: .7;
}

.u-fontAjust {
  font-feature-settings: "palt" 1;
}

.u-font-HiraginoKakuGothicProN, .c-btn a, .c-price, .c-ttl, #p-smacolle .l-pricing__hdg01, #p-smacolle .l-pricing__hdg02, #p-twiloto .l-pricing__hdg01, #p-twiloto .l-pricing__hdg02 {
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', sans-serif;
}
