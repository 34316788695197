//--------------------------------------------------
// width / height
//--------------------------------------------------

$min-height: 700px;
$min-width: 1000px;

$min-width--lower: 1200px;

$header-h--pc: 100px;
$header-h--sp: 56px;



//--------------------------------------------------
// color
//--------------------------------------------------

// $scroll-thumb-color: #0000b8;
// $scroll-rail-color: #000;

$yellow: #f2c21d;

$kakusan: #f20516;
$smacolle: #5dc3db;
$twiloto: #f2c21d;

$lightblue: #049ad7;
$orange: #ff8e23;

$gray: #333333;


//--------------------------------------------------
// breakpoint
//--------------------------------------------------

$breakpoints: (
  'SP'       : 'screen and (max-width: 768px)',
  'w1300'    : 'screen and (min-width: 769px) and (max-width: 1300px)',
  'PC'       : 'screen and (min-width: 769px)',
  'over1920' : 'screen and (min-width: 1921px)'
) !default;


@mixin SP($breakpoint: SP) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

// スマコレ・ツイロト拡縮中
@mixin w1300($breakpoint: w1300) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin PC($breakpoint: PC) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin over1920($breakpoint: over1920) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}



//--------------------------------------------------
// easing
//--------------------------------------------------

// https://easings.net/ja

// Sine（サイン）
$easeInSine     : cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine    : cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine  : cubic-bezier(0.445, 0.05, 0.55, 0.95);

// Quad（クアド）
$easeInQuad     : cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad    : cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad  : cubic-bezier(0.455, 0.03, 0.515, 0.955);

// Cubic（キュービック）
$easeInCubic    : cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic   : cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic : cubic-bezier(0.645, 0.045, 0.355, 1);

// Quart（クォート）
$easeInQuart    : cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart   : cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart : cubic-bezier(0.77, 0, 0.175, 1);

// Quint（クイント）
$easeInQuint    : cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint   : cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint : cubic-bezier(0.86, 0, 0.07, 1);

// Expo（エキスポ）
$easeInExpo     : cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo    : cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo  : cubic-bezier(1, 0, 0, 1);

// Circ（サーク）
$easeInCirc     : cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc    : cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc  : cubic-bezier(0.785, 0.135, 0.15, 0.86);

// Back（バック）
$easeInBack     : cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack    : cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack  : cubic-bezier(0.68, -0.55, 0.265, 1.55);

// カスタム
$custom01       : cubic-bezier(0.7, 0, 0.3, 1);
$custom02       : cubic-bezier(0.75,0.005,0.69,0.47);