/* --------------------------------------------------
footer
-------------------------------------------------- */
.footer {
  background: #313030;
  padding: 40px 0;

  @include SP() {
    padding: 40px 10px;
  }
}

.footer__lowerNav {
  font-size: 0;
  margin-bottom: 40px;

  @include SP() {
    margin-bottom: 30px;
  }
}

.footer__lowerNav ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__lowerNav li {
  width: 180px;
  margin: 0 10px;
  transition: opacity .3s;

  &:hover {
    opacity: .5;
  }

  @include SP() {
    width: 95px;
    margin: 0 7px;
  }
}

.footer__lowerNav img {
  width: 100%;
  height: auto;
}


.footer__link {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;

  @include SP() {
    font-size: 11px;
  }

  li {
    display: inline-block;
    margin: 0 23px;

    @include SP() {
      margin: 0 12px;

      &:first-of-type {
        display: block;
        margin-bottom: 19px;
      }
    }

    a {
      color: #fff;
      text-decoration: none;


      &:hover {
        text-decoration: underline;
      }
    }
  }
}