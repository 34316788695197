/* --------------------------------------------------
l-contact
-------------------------------------------------- */
.l-contact {
  color: #fff;
  text-align: center;
  padding: 90px 0 95px;

  @include SP() {
    padding: 60px (60/750)*100vw;
  }

  #p-smacolle & { background: $smacolle }
  #p-twiloto  & { background: $twiloto }
}

.l-contact__ttl {
  margin-bottom: 54px;

  @include SP() {
    margin-bottom: 43px;
  }
}

.l-contact__txt {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 44px;

  @include SP() {
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 23px;
  }
}

.l-contact__btn {
}