/* --------------------------------------------------
c-ttl
-------------------------------------------------- */
.c-ttl {
  @extend .u-font-HiraginoKakuGothicProN;
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  line-height: 1;

  @include SP() {
    font-size: 21px;
  }
}

.c-ttl span {
  vertical-align: middle;
  font-size: 24px;

  @include SP() {
    font-size: 15px;
  }
}