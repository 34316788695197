/* --------------------------------------------------
top
-------------------------------------------------- */
.top {
}

.top__mv {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @include SP() {
    height: (380/750)*100vw;
  }

  @include PC() {
    padding-top: (380/1920)*100%;
  }

  @include over1920() {
    padding-top: 380px;
  }
}

// 目隠し
.top__mv::before {
  z-index: 3;
  position: absolute;
  top: 0;
  right: 0;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
}

.top__mv__bg {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: no-repeat #004986 url('/assets/img/top/mv_bg.jpg') center center / auto 100%;

  @include SP() {
    background-image: url('/assets/img/top/mv_bg@sp.jpg');
    background-size: cover;
  }
}

.top__mv__lead {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: (492/1920)*100%;
  line-height: 0;

  @include PC() {
    max-width: 492px;
  }

  @include SP() {
    width: (520/750)*100vw;
  }
}

.top__mv__lead span {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;

  &:nth-of-type(n+2) {
    width: (379/492)*100%;
    margin: (32/492)*100% auto 0;

    @include SP() {
      width: (398/750)*100vw;
      margin-top: (42/750)*100vw;
    }
  }
}


// 文字の目隠し
.top__mv__lead span::before {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}


.top__mv__particle {
  z-index: 1;
  position: absolute;
  top: -10%;
  left: 0;
  width: 100%;
  height: 120%;
}