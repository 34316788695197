/* --------------------------------------------------
l-carryout
-------------------------------------------------- */
.l-carryout {
  border-top: 2px solid #f4f4f4;
  padding: 100px 0;

  @include SP() {
    padding: 60px 0;
  }
}


.l-carryout__ttl {
  margin-bottom: 97px;

  @include SP() {
    margin-bottom: 56px;
  }
}


.l-carryout__grid {
  margin: 0 auto;

  @include PC() {
    width: 960px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  @include SP() {
    width: (540/750)*100vw;
  }
}


.l-carryout__item {
  position: relative;

  @include PC() {
    width: 260px;
  }
}

// 順序変更・余白など
@include PC() {
  .l-carryout__item {
    &:nth-of-type(1) { order: 1 }
    &:nth-of-type(2) { order: 2 }
    &:nth-of-type(3) { order: 3 }
    &:nth-of-type(4) { order: 6 }
    &:nth-of-type(5) { order: 5 }
    &:nth-of-type(6) { order: 4 }

    &:nth-of-type(n+4) { margin-top: 137px; }
  }
}
@include SP() {
  .l-carryout__item:nth-of-type(n+2) {
    margin-top: 85px;
  }
}


// やじるし
.l-carryout__item::before {
  position: absolute;
  content: '';
  display: block;
  width: 40px;
  height: 73px;
  background: url('/assets/img/common/ico_arw_twiloto.png') no-repeat / contain;

  @include SP() {
    width: 33px;
    height: 50px;
    background-image: url('/assets/img/common/ico_arw_twiloto@sp.png');
    bottom: -66px;
    left: 118px;
  }
}

.l-carryout__item {
  @include PC() {
    // 右矢印　→
    &:nth-of-type(1)::before, &:nth-of-type(2)::before {
      transform: rotate(-90deg);
      top: 63px;
      right: -66px;
    }

    // 下矢印　↓
    &:nth-of-type(3)::before {
      transform: rotate(0deg);
      bottom: -103px;
      right: 110px;
    }

    // 左矢印　←
    &:nth-of-type(4)::before, &:nth-of-type(5)::before {
      transform: rotate(90deg);
      top: 63px;
      left: -67px;
    }
  }
  &:nth-of-type(6)::before { display: none; }
}


// やじるしの近くの文字
.l-carryout__item::after {
  position: absolute;
  content: '';
  display: block;
  width: 75px;
  height: 16px;
  background: no-repeat center center / contain;

  @include SP() {
    background-position: left center;
    bottom: -43px;
    left: 158px;
  }
}
.l-carryout__item {
  &:nth-of-type(1)::after { background-image: url('/assets/img/twiloto/txt_carryout01.png'); }
  &:nth-of-type(2)::after { background-image: url('/assets/img/twiloto/txt_carryout02.png'); }
  &:nth-of-type(3)::after { background-image: url('/assets/img/twiloto/txt_carryout03.png'); }
  &:nth-of-type(4)::after { background-image: url('/assets/img/twiloto/txt_carryout04.png'); }
  &:nth-of-type(5)::after { background-image: url('/assets/img/twiloto/txt_carryout05.png'); }
  &:nth-of-type(6)::after { display: none; }

  @include PC() {
    // →
    &:nth-of-type(1)::after, &:nth-of-type(2)::after {
      top: 46px;
      right: -83px;
    }

    // ↓
    &:nth-of-type(3)::after {
      bottom: -74px;
      right: 37px;
    }

    // ←
    &:nth-of-type(4)::after, &:nth-of-type(5)::after {
      height: 20px;
      top: 46px;
      left: -83px;
    }
  }
}


.l-carryout__visual {
  width: 190px;
  height: 180px;
  margin: 0 auto;

  @include SP() {
    width: (300/750)*100vw;
    height: auto;
  }

  img {
    width: 100%;
    height: auto;
  }
}

// 配達イラストのサイズ調整
@include PC() {
  .l-carryout__item:nth-of-type(6) .l-carryout__visual img {
    margin-top: -34px;
    margin-left: -4px;
    width: 206px;
  }
}


.l-carryout__txt {
  position: relative;
  font-weight: bold;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  margin-top: 35px;
  padding-left: 50px;

  @include SP() {
    line-height: 22px;
    margin-top: 20px;
  }
}

.l-carryout__txt::before {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: '';
  width: 36px;
  height: 36px;
  background: $twiloto;
  color: #fff;
  font-size: 22px;
  line-height: 36px;
  text-align: center;
  font-weight: bold;
  border-radius: 2px;

  counter-increment: number 1;
  content: counter(number);
}

.l-carryout__btn {
  margin-top: 96px;

  @include SP() {
    margin-top: 46px;
  }
}