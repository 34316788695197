/* --------------------------------------------------
animation
-------------------------------------------------- */

// ----------------------------------------
// TOP
// ----------------------------------------

// MVエリアの目隠しを外す
.top__mv::before {
  width: 100%;
  transition: width .8s .3s $easeInOutExpo;

  .is-loaded & {
    width: 0;
  }
}


// 背景が左からスライドしてくる
.top__mv__bg {
  transform: translateX(-100px) scale(1.1);
  transition: transform 1s .5s cubic-bezier(.165,.84,.44,1);

  .is-loaded & {
    transform: translateX(0);
  }
}


// リード文の目隠しを外す
.top__mv__lead span::before {
  animation: top__mv__lead .8s 1.2s both;
  animation-play-state: paused;
}
.is-loaded .top__mv__lead span:nth-of-type(n+2)::before {
  animation-delay: 1.3s;
}

.is-loaded .top__mv__lead span::before {
  animation-play-state: running;
}

@keyframes top__mv__lead {
  0% {
    transform: translate(-110%);
  }
  50%, 60% {
    transform: translate(0%);
    width: 100%;
  }
  100% {
    width: 0;
  }
}


// リード文を表示
.top__mv__lead span img {
  opacity: 0;
  transition-delay: 1.6s;

  .is-loaded & {
    opacity: 1;
  }
}
.top__mv__lead span:nth-of-type(n+2) img {
  transition-delay: 1.7s;
}


.top__mv__particle {
  opacity: 0;
  transform: scale(1.1);
  transition: 1s 2s;
  transition-property: opacity, transform;

  .is-loaded & {
    opacity: .5;
    transform: scale(1);
  }
}