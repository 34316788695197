/* --------------------------------------------------
l-feature
-------------------------------------------------- */
.l-feature {
  border-top: 2px solid #f4f4f4;
  padding: 100px 0;

  @include SP() {
    padding: 60px 0;
  }
}

.l-feature__ttl {
  margin-bottom: 83px;

  @include SP() {
    margin-bottom: 47px;
  }
}

.l-feature__grid {
  margin: 0 auto;


  @include PC() {
    width: 960px;
    display: flex;
    justify-content: space-between;
  }

  @include SP() {
    width: (540/750)*100vw;
  }
}

.l-feature__sec {
  font-weight: 600;

  @include PC() {
    width: 290px;
  }

  @include SP() {
    &:nth-of-type(n+2) {
      margin-top: 50px;
    }
  }
}

.l-feature__illust {
  width: 240px;
  height: 220px;
  margin: 0 auto 40px;

  @include SP() {
    width: (370/750)*100vw;
    height: auto;
    margin-bottom: 15px;
  }
}

.l-feature__hdg {
  position: relative;
  white-space: nowrap;
  font-size: 21px;
  line-height: 41px;
  color: #000;
  padding-left: 60px;

  @include SP() {
    font-size: 18px;
    line-height: 30px;
    padding-left: 45px;
  }

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    content: '';
    display: block;
    width: 50px;
    height: 41px;
    background: no-repeat / contain;

    #p-smacolle & { background-image: url('/assets/img/common/ico_chack_smacolle.png') }
    #p-twiloto  & { background-image: url('/assets/img/common/ico_chack_twiloto.png') }

    @include SP() {
      width: 37px;
      height: 30px;
    }
  }
}

.l-feature__desc {
  font-size: 14px;
  line-height: 28px;
  margin-top: 22px;
  font-weight: 500;

  @include SP() {
    font-size: 14px;
    line-height: 26px;
    margin-top: 20px;
  }
}

.l-feature__txt {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 75px 0 45px;

  @include SP() {
    font-size: 14px;
    line-height: 25px;
    margin: 40px 0 23px;
  }
}

.l-feature__btn {
}