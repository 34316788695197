/* --------------------------------------------------
l-mv
-------------------------------------------------- */
.l-mv {
  background: no-repeat center center / cover;
  width: 100%;
  overflow: hidden;

  @include PC() {
    min-width: $min-width--lower;
    height: 420px;
  }

  @include SP() {
    max-height: (1260/750)*100vw;
  }


  #p-smacolle & {
    background-color: #87d7e9;
    background-image: url('/assets/img/smacolle/mv_bg.jpg');

    @include SP() {
      background-image: url('/assets/img/smacolle/mv_bg@sp.jpg');
    }
  }


  #p-twiloto & {
    background-color: #f6d442;
    background-image: url('/assets/img/twiloto/mv_bg.jpg');

    @include SP() {
      background-image: url('/assets/img/twiloto/mv_bg@sp.jpg');
    }
  }

  img {
    width: 100%;
    height: auto;
  }
}


.l-mv__inner {
  position: relative;
  margin: 0 auto;
  height: 100%;

  @include PC() {
    #p-smacolle & {
      max-width: 1300px;
    }
    #p-twiloto & {
      max-width: $min-width--lower;
    }
  }
}


.l-mv__visual {
  z-index: 1;
  position: absolute;

  @include SP() {
    left: 50%;
    transform: translateX(-50%);
  }


  #p-smacolle & {
    top: 50%;
    width: (670/1300)*100%;

    @include PC() {
      left: (377/1300)*100%;
      transform: translate(-50%, -50%);
    }

    @include SP() {
      width: (710/750)*100vw;
      top: (378/750)*100vw;
    }
  }

  #p-twiloto & {
    @include PC() {
      top: 74px;
      left: 100px;
      width: 497px;
    }

    @include SP() {
      width: (670/750)*100vw;
      top: (425/750)*100vw;
    }
  }
}


.l-mv__circle {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 15px;

  @include SP() {
    top: 0;
    left: 0;
    width: 100vw;
  }
}


.l-mv__box {
  z-index: 2;
  position: relative;
  text-align: center;
  height: 100%;

  @include PC() {
    width: 630px;
    padding-bottom: 60px;

    #p-smacolle & {
      margin: 0 0 0 auto;
    }

    #p-twiloto & {
      margin: 0 20px 0 auto;
    }
  }

  @include SP() {
    padding-bottom: (83/750)*100vw;
  }
}


.l-mv__copy {
  line-height: 0;
  margin: 0 auto;


  #p-smacolle & {
    padding-top: 79px;
    width: 344px;

    @include SP() {
      width: (552/750)*100vw;
      padding-top: (100/750)*100vw;
    }
  }

  #p-twiloto & {
    padding-top: 100px;
    width: 444px;

    @include SP() {
      width: (620/750)*100vw;
      padding-top: (122/750)*100vw;
    }
  }
}


.l-mv__ttl {
  line-height: 0;
  margin: 0 auto;


  #p-smacolle & {
    padding-top: 53px;
    width: 400px;

    @include SP() {
      width: (623/750)*100vw;
      padding-top: (70/750)*100vw;
    }
  }


  #p-twiloto & {
    padding-top: 30px;
    width: 435px;

    @include SP() {
      width: (675/750)*100vw;
      padding-top: (50/750)*100vw;
    }
  }
}


.l-mv__list {

  @include PC() {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 430px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @include SP() {
    margin: (584/750)*100vw auto 0;

    > *:nth-child(n+2) {
      margin-top: (20/750)*100vw;
    }
  }


  #p-smacolle & {
    @include PC() {
      bottom: 60px;
    }
  }

  #p-twiloto & {
    @include PC() {
      bottom: 70px;
    }
  }
}