/* --------------------------------------------------
loader
-------------------------------------------------- */
.loader {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  background: $yellow;

  #p-smacolle & { background: $smacolle; }
  #p-twiloto  & { background: $twiloto; }
}

.self-building-square-spinner, .self-building-square-spinner * {
  box-sizing: border-box;
}

.self-building-square-spinner {
  height: 40px;
  width: 40px;
}

.self-building-square-spinner .square {
  height: 10px;
  width: 10px;
  top: (-10px * 2 / 3);
  margin-right: (10px / 3);
  margin-top: (10px / 3);
  background: #fff;
  float: left;
  position:relative;
  opacity: 0;
  animation: self-building-square-spinner 4s infinite;
}

.self-building-square-spinner .square:nth-child(1) {
  animation-delay: (200ms * 6);
}

.self-building-square-spinner .square:nth-child(2) {
  animation-delay: (200ms * 7);
}

.self-building-square-spinner .square:nth-child(3) {
  animation-delay: (200ms * 8);
}

.self-building-square-spinner .square:nth-child(4) {
  animation-delay: (200ms * 3);
}

.self-building-square-spinner .square:nth-child(5) {
  animation-delay: (200ms * 4);
}

.self-building-square-spinner .square:nth-child(6) {
  animation-delay: (200ms * 5);
}

.self-building-square-spinner .square:nth-child(7) {
  animation-delay: (200ms * 0);
}

.self-building-square-spinner .square:nth-child(8) {
  animation-delay: (200ms * 1);
}

.self-building-square-spinner .square:nth-child(9) {
  animation-delay: (200ms * 2);
}

.self-building-square-spinner .clear{
  clear: both;
}

@keyframes self-building-square-spinner {
  0% {
    opacity: 0;
    top: (-10px * 2 / 3);
  }
  5% {
    opacity: 1;
    top: 0;
  }
  50.9% {
    opacity: 1;
    top: 0;
  }
  55.9% {
    opacity: 0;
    top: (-10px * 2 / 3)
  }
}